import { Review, ReviewType } from "../types";

export const REVIEWS: Review[] = [
  {
    id: "1#1",
    type: ReviewType.PRESS_REVIEW,
    date: new Date("2012-04-21").toLocaleDateString(),
    writer: "Benjamin Frandzel",
    org: "San Francisco Classical Voice",
    title: "New Music on Korean Instruments",
    content: `Composer Hyo-shin Na and Citywinds are really an ideal
      match. Both have made indelible contributions to the Bay
      Area's musical life through the sheer quality of their work,
      not to mention the originality of their approaches. This
      weekend's concerts were a case in point. Not content
      simply to present new Korean works for their own
      ensemble, Citywinds invited Contemporary Music
      Ensemble Korea, leading proponents of new music on
      Korean instruments, to join them in a fascinating
      program. Na introduced the program and added three
      striking works of her own, including two world premieres.
      
      Na contributed to the program as a cultural ambassador
      of sorts, introducing the members of CMEK and leading
      a demonstration of the instruments. First there were the
      Peopgeum and Kayageum, tabled zithers very much
      like the koto; the piri, a penny whistle-sized, alternately
      sweet and piercing double-reed; the Saenghwang, a
      mouth organ which, Na explained, is the only harmonic
      instrument of Korea; and the Taegeum, a bamboo
      transverse flute whose pure tone is augmented by a
      raspiness created by a surrounding membrane. In the
      hands of this ensemble's three virtuosi, the expressive
      and timbral character of all the instruments was varied to
      a remarkable degree.
      
      Although Na has written music that is dynamic in a more
      conventional sense, the pieces on this program often
      reached their deepest intensity through a near stillness.
      Instruments focused on single sustained pitches for
      extended lengths, examining and reworking their
      timbres, expressive purpose, and interrelationships with
      the other instruments in a focused, sensitive manner. In
      all three of her works on the program, this approach
      made for fascinating listening.
      
      Different dimensions in each pitch
      
      Na makes a thorough examination of the musical
      materials at hand, searching for details and possibilities
      within her ideas. Her aesthetic worked ideally in
      Chung-Ji-Hyang, written for CMEK. The instrumentalists
      displayed a great ability to manipulate the attacks,
      intonation and colors of their instruments, finding
      different dimensions in each pitch, often hanging on
      single notes or brief figures while re-coloring the
      individual and collective timbres. There was a sense of
      this as very personal music for both composer and
      performers. The players exuded a palpable feeling for
      the shifting relationships within the ensemble, a great
      deal happening within an outwardly still framework.
      
      Na added two new works inspired by poetry. For
      Szymborska's Muse, a musical reflection of Wislawa
      Szymborska's Nothing Twice, the composer employed
      the taegeum and piri in gradually shifting relationships,
      repeating the same material in new ways. Much of the
      interest in the piece came as the players effectively
      traded places, taegeum player Jeong-Seung Kim
      gradually moving from his raspiest tone to his purest,
      while Piri player Chi-wan Park's initially soft playing
      transitioned to the instrument's most strident voice.
      Some special aural touches were included, as Kim wore
      a beaded bracelet, shaking when he added vibrato,
      which serves as a specialized expressive device in this
      music. Na explained in her introduction that Kim is the
      first taegeum player to develop multiphonics on his
      instrument, and these formed a potent addition to the
      piece as it reached its conclusion.
      
      The ensemble expanded for Akhmatova's Muse,
      inspired by Anna Akhmatova's poem The Muse. In
      keeping with the poet's account of listening to the muse,
      Na created a structure in which the ensemble of flute/alto
      flute, taegeum, oboe, piri and kayageum could respond
      to intense interaction with each other. The textures were
      among the most compelling of the day, as the two flutes
      and the two double reeds paired up and explored both
      their shared qualities and differences by holding and
      restating sustained pitches or short figures.
      
      A rich soundscape
      
      As the unpaired voice in the ensemble, kayageum player
      Jiyoung Yi was free to play more extended figures and
      sweeping lines over the sustained pitches of the winds,
      and displayed the expressive variety of this timbrally rich,
      25-string instrument. With what seemed to be a great
      deal of freedom within the score, the players extended
      the piece a bit beyond what might have been its most
      concise statement, but rewarded the audience with a
      rich soundscape and deeply focused ensemble sense.
      
      This unusual program also offered the opportunity to
      hear Korean composers who are little known here.
      Young-ja Lee, one of Korea's most distinguished
      composers, presented the world premiere of her Trio for
      flute, clarinet and bassoon. The playing was beautifully
      shaped in the slow first movement, affecting a sense of
      unity even as the lines veered further and further apart.
      The following two movements were filled with inventive
      counterpoint, lines extended and rebalanced to create a
      satisfying whole.
      
      The only work to use the full consort of Korean and
      Western instruments, Yun-Kyung Lee'sThread and
      Needle, was a brief and not terribly substantial work.
      Mixing Korean and Western folk songs, the work
      presented them, than scattered them into fragments,
      without finding its way to any greater musical purpose.
      
      In the lone conventional wind quintet on the program,
      Citywinds brought an excellent balance and collective
      expression to Isang Yun's Wind Quintet of 1991, a work
      from the end of this esteemed composer's career.
      Despite the group's committed performance, this work's
      rapid first movement fell prey to the alternating,
      roundtable-discussion style of contrapuntal writing which
      is all to common in the wind quintet idiom and quickly
      grows wearying. The slow movement that ended the
      work, however, was compelling in its casting of the group
      as an orchestra of two sections, the rumbling low voices
      of the horn and bassoon mixing with the upper voices of
      flute, clarinet and oboe. The quintet dealt with the
      challenge of the gradually bending pitches at the work's
      end with great care and musicality.
      
      (Benjamin Frandzel is a Bay Area musician and writer. In
      addition to writing concert music, he has collaborated
      with dance, theater, and visual artists, and has written
      about music for many publications and musical
      organizations. He is currently a graduate student in
      composition at San Francisco State University.)      
      `,
  },
  {
    id: "2#2",
    type: ReviewType.PRESS_REVIEW,
    date: new Date("2002-03-28").toLocaleDateString(),
    writer: "Daniel Cariaga, Times Staff Writer",
    org: "Los Angeles Times",
    source: "MUSIC REVIEW",
    title: "Svrcek Answers Demands of 'Piano Study 3'",
    content: `The world premiere of Hyo-shin Na's "Piano Study 3" was a highlight of Susan Svrcek's latest recital in the Piano Spheres series Tuesday at Neighborhood Church in Pasadena.
      A commission by the enterprising organization, it is a technically demanding piece for the instrumentalist, an intriguing one for the listener. It lives in the treble regions of the instrument, visiting the lower octaves seldom. It tinkles; it simulates Korean instruments; it assumes a walking gait; it specializes in the pentatonic mode, and it is repetitive--but both the mode and the repetitions are charming rather than irritating, for the piece lasts only eight minutes.
      The composer was present; she shared bows with the pianist.
      Another living composer, this one 35 years older than Na, was also in the commodious sanctuary of the neo-Craftsman church. Benjamin Lees wrote the two works, "Odyssey I" and "Odyssey II," that constituted most of the first half of this recital.
      They are ballad-like pieces of considerable impact that contrast gentle and warlike moods, rhapsodic and epic passages, and present a succession of shared and urgent feelings. The musical narrative in both is ongoing but not chaotic, and thoroughly compelling.
      These attractive pieces surrounded the quiet pairing of John Cage's suave, entirely diatonic "Dream" (1948) and Debussy's final solo piano piece, the meditative "Elegie" (1915). Svrcek played both exquisitely, making at least one pianist in this audience of pianists want to find these scores and play them--now.
      The program ended with two pieces of political reference by Yuji Takahashi, "Kwangju, (May 1980)," a memorial to victims of the Kwangju massacre, accompanied by projections of artwork by Taeko Tomiyama, and "Chained Hands in Prayer" (1976), a set of variations on a hymn attributed to Korean poet Ji-ha Kim, who was held prisoner in the 1970s. Svrcek gave the entire program the title "Personal Journeys."`,
  },
  {
    id: "3#3",
    type: ReviewType.PRESS_NOTICE,
    org: "Sequenza21",
    content:
      "Both works by Na are lovely as well as interesting, rewarding as well as challenging.",
  },
  {
    id: "4#4",
    type: ReviewType.PRESS_NOTICE,
    org: "San Francisco Chronicle",
    content: "Song of the Beggars was rich and urgent",
  },
  {
    id: "5#5",
    type: ReviewType.PRESS_NOTICE,
    org: "The Wire",
    content:
      "Then came the great revelation of the festival: two coruscating works by Hyo-shin Na. …an elegant yet unnerving meditation for piano.",
  },
];

import React from 'react';
import { useState, useEffect } from 'react';
import AlbumsSection from './AlbumsSection';
import YoutubeSection from './YoutubeSection';
import ReviewsSection from './ReviewsSection';
import WelcomeSection from './WelcomeSection';
import { ALBUMS, REVIEWS, YOUTUBES, CONCERTS } from '../../constants/index';
import ConcertSection from './ConcertSection';
import { Profile } from '../../types';
import { getProfile } from '../../http-requests';
import Loading from '../../components/Loading';

const Main = () => {
  const [profile, setProfile] = useState<Profile>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getProfileHttpRequest = async () => {
      const res = await getProfile();
      setProfile(res);
    };

    getProfileHttpRequest();
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <Loading />
    );
  }
  const albums = profile.recordings?.length ? profile.recordings : ALBUMS;
  const concerts = profile.performances?.length ? profile.performances : CONCERTS;
  const reviews = profile.reviews?.length ? profile.reviews : REVIEWS;
  const youtubes = profile.youtubes?.length ? profile.youtubes : YOUTUBES;

  return (
    <>
      <WelcomeSection />
      <ConcertSection concerts={concerts} />
      <AlbumsSection albums={albums} />
      <YoutubeSection youtubes={youtubes} />
      <ReviewsSection reviews={reviews} />
    </>
  );
};

export default Main;

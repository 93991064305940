import { styled } from "@mui/system"
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useState } from "react";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  '&:before': {
    opacity: 0,
  },
  padding: 0,
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  padding: 0,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const ProgramNote = ({ programNote }: { programNote: string }) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  const onClick = (event: React.SyntheticEvent) => {
    setExpanded(prevStatus => !prevStatus);
  }

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary onClick={onClick}>
        <Typography>Program Notes</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body1" sx={{whiteSpace: "pre-line"}}>{programNote}</Typography>
      </AccordionDetails>
    </Accordion>
  )
}

export default ProgramNote
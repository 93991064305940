import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Divider, Typography, Box, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Review } from "../../types";
import { dateFormatter } from "../../utils/date";

export interface ReivewListItemProps {
  review: Review;
}

const ReviewListItem: React.FC<ReivewListItemProps> = ({ review }) => {
  const navigate = useNavigate();
  const { category } = useParams();

  const handleClickReivewItem = () => {
    const reviewId = review.id.split("#")[1];
    navigate(`/reviews/${category}#${reviewId}`);
  };

  return (
    <Box
      sx={{
        mb: "0.5rem",
      }}
    >
      <Divider />
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: {
            md: "row",
            lg: "row",
          },
          justifyContent: "space-between",
          mt: "1rem",
        }}
      >
        <Typography variant="pageH3">{review.title}</Typography>
        <Typography variant="pageH3" color="primary.main">
          {review.date && dateFormatter(review.date, true, true, false)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "-webkit-box",
          whiteSpace: "normal",
          wordBreak: "break-word",
          wordWrap: "break-word",
          overflow: "hidden",
          textOverflow: "ellipsis",
          "-webkit-line-clamp": "3",
          "-webkit-box-orient": "vertical",
          mt: "0.5rem",
        }}
      >
        {review.content}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="text"
          sx={{ fontSize: "0.75rem" }}
          onClick={handleClickReivewItem}
        >
          Read More <ArrowForwardIcon sx={{ fontSize: "1rem" }} />
        </Button>
      </Box>
    </Box>
  );
};

export default ReviewListItem;

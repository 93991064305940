import { Box, Container, Divider, Typography } from "@mui/material";
import SNSButton from "../components/SNSButton";

import { FOOTER_HEIGHT } from "../constants/index";
import { MdEmail } from "@react-icons/all-files/md/MdEmail";
import { SiFacebook } from "@react-icons/all-files/si/SiFacebook";
import { GrFacebook } from "@react-icons/all-files/gr/GrFacebook";
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { styled } from "@mui/material/styles";


const StyledTooltip = styled('div')(({ theme }) => ({
  "&": {
    position: "relative",
    display: "inline-block"
  },
  "& .MuiTypography-root": {
    visibility: "hidden",
    backgroundColor: theme.palette.primary.main,
    color: "#FFF",
    padding: "0.5rem",
    textAlign: "center",
    borderRadius: "0.5rem",
    position: "absolute",
    zIndex: 1,
    width: "fit-content",
    bottom: "100%",
    left: "50%",
    marginLeft: "-60px",
    whiteSpace: "nowrap",
  },
  "&:hover .MuiTypography-root": {
    visibility: "visible"
  }
}));

const icons: { name: string; url: string; component: JSX.Element }[] = [
  {
    name: "e-mail",
    url: "mailto:hyoshinnaemail@gmail.com",
    component: <MdEmail />,
  },
  {
    name: "Hyoshin Na Facebook",
    url: "https://www.facebook.com/HyoshinNa",
    component: <SiFacebook />,
  },
  {
    name: "Wooden Fish Ensemble Facebook",
    url: "https://www.facebook.com/WoodenFishEnsemble",
    component: <GrFacebook />,
  },
  {
    name: "Thomas Schultz Piano Seminar Facebook",
    url: "https://www.facebook.com/The-Thomas-Schultz-Summer-Piano-Seminar-at-Stanford-497752504087174",
    component: <FaFacebookF />,
  },
];

const Footer: React.FC = () => {
  return (
    <footer style={{width: "100vw"}}>
      <Container
        sx={{
          height: FOOTER_HEIGHT,
          maxWidth: {lg: "unset"},
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Divider variant="middle" style={{ marginBottom: "1.5rem" }} />
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems={{ xs: "center", md: "unset" }}
          justifyContent="center"
          mb={1.5}
        >
          <Typography variant="body1" gutterBottom>
            Copyright @ Hyo-shin Na {new Date().getFullYear()}.
          </Typography>
          <Typography sx={{ marginLeft: { xs: "0", md: "0.5rem" } }}>
            All Rights Reserved.
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="center">
          {icons.map((icon) => {
            return (
              <StyledTooltip key={icon.name}>
                <SNSButton key={icon.name} IconName={icon.name} url={icon.url}>
                  {icon.component}
                </SNSButton>
                <Typography component="span">{icon.name}</Typography>
              </StyledTooltip>
            );
          })}
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { MENUS, WRITINGS } from "../../constants";
import { Writing } from "../../types";
import { Container, Typography, Box } from "@mui/material";
import PageTitle, { PageTitlePropsType } from "../../components/PageTitle";
import PageBannerImg from "../../components/PageBannerImg";
import WritingListItem from "./WritingListItem";
import WritingDetail from "./WritingDetail";
import { getWritings } from "../../http-requests";
import Loading from "../../components/Loading";

const Writings = () => {
  const [writings, setWritings] = useState<Writing[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentWriting, setCurrentWriting] = useState<Writing | null>(null);
  const { category } = useParams();
  const { hash } = useLocation();
  const siblingPages = MENUS.find(menu => menu.title.toLowerCase() === "writings")?.subMenu as PageTitlePropsType["siblingPages"];

  useEffect(() => {
    const getWritingsHttpRequest = async () => {
      const res: Writing[] | null = await getWritings();
      res ? setWritings(res) : setWritings(WRITINGS);
    };

    getWritingsHttpRequest();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!hash) {
      return setCurrentWriting(null);
    }
    const foundWriting = writings.find((writing) => writing.id.split("#")[1] === hash.split("#")[1]);
    if (!foundWriting) return;
    setCurrentWriting(foundWriting);
  }, [hash, writings]);

  if (isLoading) {
    return (
      <Loading />
    )
  }

  let previousWritingId, nextWritingId;

  if (currentWriting) {
    const index = writings.findIndex(
      (writing) => writing.id.split("#")[1] === hash.split("#")[1]
    );

    const prevIndex = index > 0 ? index - 1 : -1;
    previousWritingId =
      prevIndex >= 0 ? writings[prevIndex].id.split("WRITING#")[1] : undefined;

    const nextIndex = index > 0 ? index + 1 : 1;
    nextWritingId =
      nextIndex >= 0 && nextIndex < writings.length
        ? writings[nextIndex].id.split("WRITING")[1]
        : undefined;
  }

  return (
    <Container sx={{ padding: { xs: "0", md: "1rem" } }}>
      <PageTitle title={category ?? "Writing"} siblingPages={siblingPages}/>
      <PageBannerImg
        imgUrl="https://s3.amazonaws.com/images.hyoshin.na/photos/hsnaWorking2021.jpg"
        imgTitle="writings"
      />
      {currentWriting ? (
        <WritingDetail 
        writing={currentWriting}
        previousWritingId={previousWritingId}
        nextWritingId={nextWritingId}
        />
      ): (
        <Box sx={{ my: {xs: "1rem", md: "3rem"}, padding: "0 1rem"}}>
          {writings.filter(writing => {
           switch(category?.toLowerCase()) {
              case 'koreatimessf': 
                return ['koreatimes', 'essay'].includes(writing.category.replaceAll(' ', '').toLowerCase());
              case 'author': 
                return writing.category.replaceAll(' ', '').toLowerCase() === 'author';
              case 'writingsinkorean': 
                return writing.category.replaceAll(' ', '').toLowerCase() === 'writingsinkorean';
              default:
                return writing
            }
          })
          .sort((a, b) => new Date(a.date!) > new Date(b.date!) ? 1 : -1)
          .map((writing: Writing) => {
            return <WritingListItem key={writing.id} writing={writing}/>
          })}
        </Box>
      )}
    </Container>
  );
};

export default Writings;

import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Menu as MenuType } from "../types";
import { StyledButton } from "./MenuBar.styled";

const MenuBar = ({ title, link, subMenu }: MenuType) => {
  const path = window.location.pathname;
  const parsedPaths = path.match(/\/\w+/g);
  const onPage =
    parsedPaths &&
    (parsedPaths[0].replace("/", "").toLowerCase().trim() ===
      title.replace(" ", "").toLowerCase() ||
      (title.toLowerCase() === "ts piano seminar" &&
        parsedPaths[0].replace("/", "").toLowerCase() === "seminar"));
  const className = onPage ? "active" : "";

  if (subMenu) {
    return (
      <>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            "&:hover .MuiBox-root": { display: "grid" },
          }}
        >
          <StyledButton
            id={title}
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded="true"
            color="info"
            disableRipple
            endIcon={<KeyboardArrowDownIcon color="info" />}
            className={className}
          >
            {title}
          </StyledButton>
          <Box
            sx={{
              position: "absolute",
              display: "none",
              gridTemplateColumns: `repeat(${subMenu.length}, auto)`,
              backgroundColor: "primary.main",
              borderRadius: "0.5rem",
              width: "fit-content",
              top: "85%",
              left: "0",
              whiteSpace: "nowrap",
            }}
          >
            {subMenu.map((menu) => {
              return (
                <Link
                  key={menu.title}
                  href={menu.link}
                  underline="none"
                  variant="button"
                  sx={{
                    "&:hover": { fontWeight: "700" },
                    fontSize: "0.75rem",
                    color: "primary.contrastText",
                    padding: "0.35rem 0.75rem",
                    textAlign: "center",
                  }}
                >
                  {menu.title}
                </Link>
              );
            })}
          </Box>
        </Box>
      </>
    );
  } else {
    return (
      <StyledButton
        color="info"
        disableRipple
        href={link}
        className={className}
      >
        {title}
      </StyledButton>
    );
  }
};

export default React.memo(MenuBar);

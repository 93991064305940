import { Container, Typography, Box, Link } from '@mui/material';
import React, { useEffect, useState } from "react";
import Loading from '../../components/Loading';
import PageBannerImg from '../../components/PageBannerImg';
import PageTitle from '../../components/PageTitle';
import { getDissertations } from '../../http-requests';
import { Dissertations as DissertationsType } from '../../types';
import DissertationCard from './DissertationCard';

const Dissertations = () => {
  const [dissertations, setDissertations] = useState<DissertationsType>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getDissertationHttpRequest = async () => {
      const res = await getDissertations();
      if(res && Object.keys(res).length) setDissertations(res);
    }
    getDissertationHttpRequest();
    setIsLoading(false);
  }, [getDissertations])

  if(isLoading) {
    return <Loading />
  }

  return (
    <Container sx={{ padding: { xs: '0', md: '1rem' } }}>
      <Box>
        <PageTitle title="Dissertations" />
        <PageBannerImg imgUrl="https://s3.amazonaws.com/images.hyoshin.na/pages/dissertation_cover_photo.jpg" imgTitle="dissertation image"/>
        <Box sx={{padding: "1rem"}}>
          {Object.keys(dissertations).map((degree) => {
            return (
              <Box key={degree} sx={{display: "flex", flexDirection: "column"}}>
                {Object.keys(dissertations[degree as any]).map((language) => {
                  const title = degree === 'doctor' 
                              ? `Doctor of Musical Arts Dissertations on Hyo-shin Na's Music in ${language}`
                              : `Master's Degree Dissertations on Hyo-shin Na's Music in ${language}` 
                        ;
                  return (
                    <Box key={`${degree}-${language}`}sx={{marginBottom: 3}}>
                      <Typography variant="pageH3">{title}</Typography>
                      {dissertations[degree as any][language as any].map((dissertation, index) => {
                        if(!dissertation.isActive) return;
                        return <DissertationCard key={index} dissertation={dissertation} />
                      })}
                    </Box>
                  )
                })}
              </Box>
            )
          })}
        </Box>
      </Box>
    </Container>
  )
}

export default Dissertations;


import { Album, Youtube } from "../types";

export const ALBUMS: Album[] = [
  {
    id: '1',
    title:'Hyo-shin Na: Blue Yellow River',
    subtitle:'',
    imageUrl:'https://s3.amazonaws.com/images.hyoshin.na/cds/Blue_Yellow_River.jpeg',
    publisher:'Top Arts Inc.',
    link:'Woodenfishnet@aol.com',
    recordNumber:'TOPCD - 044'
  },
  {
    id: '2',
    title:'Del Sol String Quartet - Ring of Fire',
    subtitle:'Music by Kui Dong, Chinary Ung, Jack Body, Gabriela Lena Frank, Hyo-shin Na, Zhou Long, John Adams, Peter Sculthorpe, and  an improvisation by the Del Sol String',
    imageUrl:'https://s3.amazonaws.com/images.hyoshin.na/cds/Del_Sol_String_Quartet.jpg_1.jpg',
    publisher:'',
    link:'',
    recordNumber:'',
  },
];

// TODO: update id/title/subtitle/link
//Youtube posts
export const YOUTUBES: Youtube[] = [
  {
    id: "y01",
    title:
      "THOMAS SCHULTZ, pianist, plays Bach-Busoni, Rzewski, and Hyo-shin Na",
    date: new Date(2022, 3, 10).toLocaleDateString(),
    content: `THOMAS SCHULTZ, pianist, plays Bach-Busoni, Rzewski, and Hyo-shin Na 
    Wachet auf, ruft uns die Stimme & Ich ruf’ zu dir, Herr
    by J. S. Bach / Busoni`,
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/youtube1.png",
    youtubeUrl: "https://www.youtube.com/watch?v=ynVCuorBF-E",
  },
  {
    id: "y02",
    title: `Beethoven - Bagatelles, Op. 126 #1
    Mark Dalrymple, recording engineer`,
    date: new Date(2013, 4, 16).toLocaleDateString(),
    content: `Thomas Schultz, piano 
    Mark Dalrymple, recording engineer`,
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/youtube2.png",
    youtubeUrl: "https://www.youtube.com/watch?v=ciugLTAIwWo",
  },
  {
    id: "y03",
    title: "Bagatelle#2 Beethoven - Bagatelles, Op. 126 #2",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/youtube2.png",
    date: new Date(2013, 4, 17).toLocaleDateString(),
    content: `Beehoven: Die 32 Klaviersonaten, Eroica-Variationen, Diabelli-Variationen & Sechs Bagatellen`,
    youtubeUrl: "https://www.youtube.com/watch?v=Z3bsyuS7s8s",
  },
];

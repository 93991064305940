import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { PHOTOS } from "../../constants";
import { REACT_APP_IMAGE_BASE_URL } from "../../constants/env";
import PageTitle from "../../components/PageTitle";
import { getPhotos } from "../../http-requests";
import PhotosPanel from "../../components/PhotosPanel";
import Loading from "../../components/Loading";

const Photos = () => {
  const [photos, setPhotos] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getPhotosHttpRequest = async () => {
      const res = await getPhotos();
      res?.length ? setPhotos(res) : setPhotos(PHOTOS);
    };
    getPhotosHttpRequest();
    setIsLoading(false);
  }, [getPhotos, setPhotos, setIsLoading]);

  if(isLoading) {
    return <Loading />
  }

  return (
    <Container sx={{ padding: { xs: "0", md: "1rem" } }}>
      <PageTitle title="Photos" />
      <PhotosPanel photos={photos} baseUrl={REACT_APP_IMAGE_BASE_URL}/>
    </Container>
  );
};

export default Photos;

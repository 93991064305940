import {
  Box,
  Container,
} from "@mui/material";
import Carousel from "react-multi-carousel";
import PageTitle from "../../components/PageTitle";
import { SCORE_CAROUSEL_BREAKPOINTS } from "../../constants";

const scoreSamples = [
  {
    title: 'almost nothing sample',
    url: 'https://s3.amazonaws.com/images.hyoshin.na/score/almost_nothing_sample.png',
  },
  {
    title: 'cloud sample',
    url: 'https://s3.amazonaws.com/images.hyoshin.na/score/clouds_sample.png',
  },
  {
    title: 'kayageum music sample',
    url: 'https://s3.amazonaws.com/images.hyoshin.na/score/kayageum_music_sample.png',
  },
  {
    title: 'kayageum song sample',
    url: 'https://s3.amazonaws.com/images.hyoshin.na/score/kayageum_song_sample.png',
  },
  {
    title: 'song of pure nothing sample',
    url: 'https://s3.amazonaws.com/images.hyoshin.na/score/song_of_pure_nothing_sample.png',
  },
  {
    title: 'song of the firewood sample',
    url: 'https://s3.amazonaws.com/images.hyoshin.na/score/song_of_the_firewoood_sample.png',
  },
  {
    title: 'sorrow like rain sample',
    url: 'https://s3.amazonaws.com/images.hyoshin.na/score/sorrow_like_rain_sample.png',
  },
  {
    title: 'the wind sample',
    url: 'https://s3.amazonaws.com/images.hyoshin.na/score/the_wind_sample.png',
  },
  {
    title: 'rain study sample 1',
    url: 'https://s3.amazonaws.com/images.hyoshin.na/score/rain_study_sample1.jpeg',
  },
  {
    title: 'rain study sample 2',
    url: 'https://s3.amazonaws.com/images.hyoshin.na/score/rain_study_sample2.jpeg',
  },
]

const Score = () => {
  return (
    <Container sx={{ padding: { xs: "0", md: "1rem" } }}>
      <PageTitle title="Scores" />
      <Box mt={3}>
        <Carousel
          responsive={SCORE_CAROUSEL_BREAKPOINTS}
          autoPlay={true}
          draggable={true}
          infinite={true}
        >
          {scoreSamples.map(({title, url}, index) => <img key={index} src={url} width="98%" />)}
        </Carousel>
      </Box>
    </Container>
  );
}

export default Score;
import { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  CardMedia,
  Stack,
} from "@mui/material";
import { getBio } from "../../http-requests";
import { Bio } from "../../types";
import theme from "../../theme";
import Loading from "../../components/Loading";

const styles = {
  display: "flex",
  alignItems: "center",
  borderBottom: { md: "1px solid rgba(0, 0, 0, 0.12)" },
  padding: { xs: "0.5rem 0", md: "1rem 0" },
  "& .MuiTypography-root": {
    flexGrow: 1,
  },
  "& .MuiBox-root .MuiButton-root": {
    ml: "1rem",
    boxShadow: "unset",
  },
  "& .MuiBox-root .MuiButton-root.color-primary": {
    backgroundColor: theme.palette.primary.main,
  },
  "& .MuiBox-root .MuiButton-root.color-mediumGray": {
    backgroundColor: theme.palette.mediumGray.main,
  },
};

const Biography = () => {
  const [bioEng, setBioEng] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getBioHttpRequest = async () => {
      const res: Bio[] | [] = await getBio();
      if (res && !!res.length) {
        setBioEng(
          res.find((bio) => bio.language === "english")?.description ?? ""
        );
      }
    };

    getBioHttpRequest();
    setIsLoading(false);
  }, [setIsLoading, getBio, setBioEng]);

  return (
    <>
      <Container sx={{ padding: { xs: "0", md: "1rem" } }}>
        <Box sx={styles}>
          <Typography variant="pageH1" component="h1">
          Biography
          </Typography>
          
        </Box>
        {isLoading && <Loading />}
        {!isLoading && (
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <Box
              sx={{
                pt: { xs: 0, md: 6 },
              }}
            >
              <CardMedia
                component="img"
                image="https://s3.amazonaws.com/images.hyoshin.na/photos/hsnaAsianHead.png"
                alt="Hyo-shin Na biography photo"
                sx={{
                  width: { xs: "100%", md: "400px" },
                  filter: "grayscale(100%)",
                  borderRadius: { md: "30px" },
                }}
              />
            </Box>
            <Box
              sx={{
                p: { xs: 2, md: 4 },
                pr: { md: 0 },
              }}
            >
              <Typography
                variant="body1"
                color="inherit"
                paragraph
                sx={{ whiteSpace: "pre-line", paddingTop: "1rem" }}
              >
                {bioEng}
              </Typography>
            </Box>
          </Stack>
        )}
      </Container>
    </>
  );
};

export default Biography;

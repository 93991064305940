import React, { useEffect, useState } from "react";
import { SelectChangeEvent, Typography } from "@mui/material";
import { Container, Box } from "@mui/system";
import { WorksType, WorkType } from "../../types";
import { WORKS } from "../../constants";
import PageTitle, { PageTitlePropsType } from '../../components/PageTitle';
import PageBannerImg from '../../components/PageBannerImg';
import WorkCard from "./WorkCard";
import { getWorks } from "../../http-requests/WorksHttpRequest";
import Filter from "../../components/Filter";
import Loading from "../../components/Loading";



const Works = () => {
  const [works, setWorks] = useState<WorksType>({});
  const [isLoading, setIsLoading] = useState(true);
  const [workYearFilterOptions, setWorkYearFilterOptions] = useState<string[]>([]);
  const [worksToRender, setWorksToRender] = useState<WorksType>({});
  const imageUrl = "https://s3.amazonaws.com/images.hyoshin.na/photos/hsewha2018c.jpg";

  const handleFilter = (e: SelectChangeEvent, option: string) => {
    if(option === 'all') return setWorksToRender(works);
    setWorksToRender(Object.assign({}, {[option]: works[(option as any as number)]}));
  }

  useEffect(() => {
    const getWorksHttpRequest = async () => {
      const res = await getWorks();
      res ? setWorks(res) : setWorks(WORKS);
      setWorkYearFilterOptions(Object.keys(res).sort((a, b) => (Number(b) - Number(a))));
    }

    getWorksHttpRequest();
    setIsLoading(false);
  }, [setIsLoading])

  useEffect(() => {
    setWorksToRender(works);
  }, [works])

  if (isLoading) {
    return (
      <Loading />
    );
  }

  return (
    <Container sx={{ padding: { xs: '0', md: '1rem' } }}>
      <PageTitle title={"List of Works"} />
      <Box>
        <PageBannerImg imgUrl={imageUrl} imgTitle="piano concert" />
        {workYearFilterOptions.length > 1 && <Filter options={workYearFilterOptions} handleFilter={handleFilter}/>}
        <Box sx={{padding: "0 1rem"}}>
          {Object.keys(worksToRender).sort((a, b) => (Number(b) - Number(a)))?.map((year: string) => {
            return (
              <Box key={year} sx={{marginTop: 3}}>
                <Typography variant="pageH2" component="h2" sx={{padding: {md: "1rem"}}} color="primary">{year}</Typography>
                {works[Number(year)].map((work: WorkType, index) => {
                  return <WorkCard key={index} work={work} />
                })}
              </Box>
            )
          })}
        </Box>
      </Box>
    </Container>
  )
}

export default React.memo(Works)

import { Box } from "@mui/material";
import { Review } from "../../types";
import PressNoticeCard from "./PressNoticeCard";

export interface PressNoticesProps {
  reviews: Review[];
}

const PressNotices: React.FC<PressNoticesProps> = ({ reviews }) => {
  return (
    <Box
      sx={{
        display: { xs: "flex" },
        flexDirection: {
          xs: "column",
        },
        justifyContent: "space-between",
        padding: "0 1rem",
        mt: "2rem",
      }}
    >
      {reviews.map((review: Review) => {
        return <PressNoticeCard key={review.id} review={review} />;
      })}
    </Box>
  );
};

export default PressNotices;

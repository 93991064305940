import { Box, Button, Typography } from "@mui/material";
import theme from "../theme";

export interface PageTitlePropsType {
  title: string;
  siblingPages?: {
    title: string;
    link: string;
  }[];
}

const styles = {
  display: "flex",
  alignItems: "center",
  borderBottom: { md: "1px solid rgba(0, 0, 0, 0.12)" },
  padding: { xs: "0.5rem 0", md: "1rem 0" },
  "& .MuiTypography-root": {
    flexGrow: 1,
  },
  "& .MuiBox-root .MuiButton-root": {
    ml: "1rem",
    boxShadow: "unset",
  },
  "& .MuiBox-root .MuiButton-root.color-primary" : {
    backgroundColor: theme.palette.primary.main,
  },
  "& .MuiBox-root .MuiButton-root.color-mediumGray" : {
    backgroundColor: theme.palette.mediumGray.main,
  },
};

const PageTitle = ({ title, siblingPages }: PageTitlePropsType) => {
  const currentPage = siblingPages?.find((page) => page.title.toLowerCase().trim().replaceAll(' ', '') === title.toLowerCase().trim().replace(' ', ''));
  return (
    <Box sx={styles}>
      <Typography variant="pageH1" component="h1">
        {currentPage ? currentPage.title : title}
      </Typography>
      {siblingPages && (
        <Box sx={{display: { xs: "none", md: "block"}}}>
          {siblingPages.map((page, index) => (
            <Button
              key={index}
              href={page.link}
              variant="contained"
              className={title.toLowerCase().trim().replaceAll(' ', '') === page.title.toLowerCase().trim().replaceAll(' ', '')
                ? "color-primary"
                : "color-mediumGray"
            }
            >
              {page.title}
            </Button>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default PageTitle;

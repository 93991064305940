export const PHOTOS: string[] = [
  "author_hwang.jpeg",
  "ewhaaward2021.jpg",
  "hsNaAsianArtMuseum.jpg",
  "hsewha2018c.jpg",
  "hsmaybeck.jpg",
  "hsnaAfricandress.jpg",
  "hsnaAsianHead.png",
  "hsnaBluejeandress.jpg",
  "hsnaColorful.jpg",
  "hsnaEwha02.jpg",
  "hsnaFace2019.png",
  "hsnaHermit01.jpg",
  "hsnaPleasureGarden.jpg",
  "hsnaReadingEwha.jpg",
  "hsnaSeoulCafe.jpg",
  "hsnaWorking2021.jpg",
  "hsnacafe.jpg",
  "hsnadaegu.jpg",
  "hsnadols.jpg",
  "hsnagaya01.jpg",
  "hsnahead2021.jpg",
  "hsnamaybeck02.jpg",
  "hsnaredred.jpg",
  "hsnaworking2021B.jpg",
  "hsnayoungrangho.jpg"
]

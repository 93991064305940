import { Publish } from "../../types";
import {
  Box,
  Typography,
} from "@mui/material";
import { useState } from "react";
import PublishModal from "./PublishModal";


const PublishItem = ({publish} : {publish: Publish}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const {
    category,
    coverImageUrl,
    description,
    id,
    isActive,
    itemNumber,
    title
  } = publish;
  if(isActive !== 'y') return <></>;
  

  const handleModalOpen = () => {
    setIsModalOpen(true);
  }

  const handleModalClose = () => {
    setIsModalOpen(false);
  }

  return (
    <Box sx={{display: "flex", flexDirection:"row", padding: "0 1rem"}}>
      <Typography variant="body1" component="h3" fontWeight="bold" color="primary" sx={{'&:hover': {cursor: "pointer"}}} onClick={handleModalOpen}>{title}</Typography>
      <Typography variant="body1" component="h3" sx={{marginLeft: 3}}>{itemNumber}</Typography>
      <PublishModal open={isModalOpen} handleClose={handleModalClose} publish={publish}/>
    </Box>
  )
}

export default PublishItem;
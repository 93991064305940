import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Publish } from "../../types";

interface PublishModalProps {
  open: boolean;
  handleClose: (event: React.MouseEvent) => void;
  publish: Publish;
}

const StyledBox = styled(Box)(({ theme }) => ({
  "&": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minHeight: "50%",
    maxHeight: "100vh",
    maxWidth: "600px",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    paddingBottom: "2rem",
    borderBottom: `8px solid ${theme.palette.primary.main}`,
    overflowY: "auto",
    outline: "none",
  },
}));

const PublishModal = ({ open, handleClose, publish }: PublishModalProps) => {
  const {
    category,
    coverImageUrl,
    description,
    id,
    isActive,
    itemNumber,
    title
  } = publish;

  return (
    <Modal open={open} onClose={handleClose}>
      <StyledBox sx={{ width: { xs: "100vw", md: "50vw" } }}>
        <CardActions sx={{ alignSelf: "flex-end" }}>
          <IconButton color="info" aria-label="close modal" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </CardActions>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContents: "space-between",
          }}
        >
          <Typography
            variant="mainH4"
            component="h3"
            sx={{ textAlign: "center"}}
          >
            {title}
          </Typography>
          {category && <Typography
            variant="subtitle1"
            component="h3"
            sx={{ textAlign: "center", mb: 2, maxWidth: "360px" }}
          >
            {category}
          </Typography>}
          {coverImageUrl && (
            <CardMedia
              component="img"
              src={coverImageUrl}
              alt={title}
              sx={{
                width: {xs: "100%", md: "calc(100% - 4rem)"},
                maxWidth: "360px",
                borderRadius: "10px",
                mt: 2,
                mb: 2,
              }}
            ></CardMedia>
          )}
          {!coverImageUrl && (
            <Box
              sx={{
                width: 240,
                height: 200,
                backgroundColor: "info",
              }}
            />
          )}
          <Typography variant="body2" component="div">{itemNumber}</Typography>
        </CardContent>
      </StyledBox>
    </Modal>
  );
};

export default PublishModal;

import { Concert } from "../types";

export const CONCERTS: Concert[] = [
  {
    isActive: "y",
    location: "Maybeck Studio, Berkeley",
    startDate: "2022-12-11T00:00:00.000Z",
    programs: [
      {
        title: "Three Piano Pieces, D. 946",
        composer: "Franz Schubert",
      },
      {
        title: "Three Albumleaves",
        composer: "Ferruccio Busoni",
      },
      {
        title: "Five Friends (2021)",
        composer: "Hyo-shin Na",
        tag: "World Premiere",
      },
      {
        title: "- from Four Pieces for Piano: Nrs. 3 and 4",
        composer: "Frederic Rzewski",
      },
    ],
    createdAt: "2022-12-11T00:00:00.000Z",
    id: "CONCERT#30d4a6ba-1ca1-40d9-bfd7-1064ed92ff9f",
    timeZone: "America/Los_Angeles",
    title: "Solo Recital",
    entityTimestamp: "CONCERT#2022-12-11T00:00:00.000Z",
  },
  {
    isActive: "y",
    location: "Old First Concerts, San Francisco",
    startDate: "2022-10-09T00:00:00.000Z",
    programs: [
      {
        title: "Piano, Violin, Viola, Cello",
        composer: "Morton Feldman",
      },
      {
        title: "Quadrangle of Light (2021)",
        composer: "Hyo-shin Na",
        tag: "World Premiere",
      },
    ],
    createdAt: "2022-10-09T00:00:00.000Z",
    id: "CONCERT#23dbdb15-2f7c-43f4-ae7d-b73d431e47eb",
    timeZone: "America/Los_Angeles",
    title: "Wooden Fish Ensemble",
    entityTimestamp: "CONCERT#2022-10-09T00:00:00.000Z",
  },
  {
    isActive: "y",
    location: "Campbell Recital Hall, Stanford University",
    startDate: "2022-10-08T00:00:00.000Z",
    programs: [
      {
        title: "Piano, Violin, Viola, Cello",
        composer: "Morton Feldman ",
      },
      {
        title: "Quadrangle of Light (2021)",
        composer: "Hyo-shin Na",
        tag: "World Premiere",
      },
    ],
    createdAt: "2022-10-08T00:00:00.000Z",
    id: "CONCERT#8c5427bf-4971-419d-8159-8662fae1211f",
    timeZone: "America/Los_Angeles",
    title: "",
    entityTimestamp: "CONCERT#2022-10-08T00:00:00.000Z",
  },
];

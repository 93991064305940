import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SectionCard from "../../components/SectionCard";
import AlbumCard from "../../components/AlbumCard";
import { Album } from "../../types/index";
import { ALBUM_CAROUSEL_BREAKPOINTS } from "../../constants";

const AlbumsSection = ({ albums }: { albums: Album[] }) => {
  return (
    <SectionCard
      title="Recordings"
      styles={{ backgroundColor: "backgroundPurple.main" }}
    >
      <Carousel
        responsive={ALBUM_CAROUSEL_BREAKPOINTS}
        autoPlay={true}
        draggable={true}
        infinite={true}
      >
        {albums.map((album) => {
          return (
            <AlbumCard
              key={album.id}
              album={album}
            />
          );
        })}
      </Carousel>
    </SectionCard>
  );
};

export default AlbumsSection;

import { Dissertation } from "../../types";
import { styled } from "@mui/material/styles";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  ListItem,
  Typography,
} from "@mui/material";

const StyledCard = styled(Card)(({ theme }) => ({
  "&": {
    minHeight: "unset",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "noWrap",
    alignItems: "left",
    borderRadius: "0",
    padding: "0.5rem",
    borderBottom: "0.5px solid rgb(0 0 0 / 20%)",
    boxShadow: "none",
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    "&:last-of-type": {
      borderBottom: "unset",
    },
  },
}));

const DissertationCard = ({dissertation}: {dissertation: Dissertation}) => {
  const {
    instrument,
    publisher,
    title,
    writer,
    year
  } = dissertation;

  return (
    <StyledCard sx={{display: "flex", flexDirection: "column"}}>
      <Typography>{`${writer}, ${instrument} (${year})`}</Typography>
      <Typography>{title}</Typography>
      <Typography>{publisher}</Typography>
    </StyledCard>
  )
}

export default DissertationCard;
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#CAC7EA",
      main: "#4F46BA",
      dark: "#373182",
      contrastText: "#fff",
    },
    secondary: {
      main: "#F8B83C",
    },
    info: {
      main: "#64607D",
    },
    neutral: {
      main: "#64607D",
    },
    lightGray: {
      main: "#CDCDCD",
    },
    mediumGray: {
      main: "#b2b2b2",
    },
    backgroundPurple: {
      main: "#e8e5ff",
    }
  },
  typography: {
    fontFamily: ["Manrope", "sans-serif"].join(","),
  },
  components: {
    MuiLink: {
      defaultProps: {
        fontFamily: ["Manrope", "sans-serif"].join(","),
      }
    }
  }
});

theme.typography.mainH1 = {
  fontWeight: 800,
  textTransform: "capitalize",
  lineHeight: "120%",
  [theme.breakpoints.down("md")]: {
    fontSize: "2.25rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.75rem", //44px
  },
};

theme.typography.mainH2 = {
  fontWeight: 800,
  textTransform: "capitalize",
  letterSpacing: "-0.03em",
  [theme.breakpoints.down("md")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3.25rem", //56px
  },
};

theme.typography.mainH3 = {
  fontWeight: 700,
  textTransform: "capitalize",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.875rem", //30px
  }
};

theme.typography.mainH4 = {
  fontWeight: 700,
  textTransform: "capitalize",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.25rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem", //24px
},
};

theme.typography.mainH5 = {
  fontWeight: 700,
  textTransform: "capitalize",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.125rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.25rem", //20px
},
};

theme.typography.pageH1 = {
  fontWeight: 800,
  textTransform: "capitalize",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.375rem", //22px
    lineHeight: "2.75rem", //44px
    textAlign: "center",
    backgroundColor: theme.palette.primary.light
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.625rem", //42px
  }
};

theme.typography.pageH2 = {
  fontWeight: 800,
  textTransform: "capitalize",
  [theme.breakpoints.up("md")] : {
    fontSize: "1.5rem", //24px
  }
};

theme.typography.pageH3 = {
  fontWeight: 800,
  textTransform: "capitalize",
  [theme.breakpoints.up("md")] : {
    fontSize: "1.25rem", //20px
  }
};

theme.typography.subtitle1 = {
  fontWeight: 500,
  textTransform: "capitalize",
};

export default theme;

import HttpRequest from "./HttpRequest";
import { Bio, ExtendedBio } from "../types";

export const getBio = async () => {
  try {
    const response: Bio[] = await HttpRequest.get("/bios");
    return response;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getBioWithReviews = async () => {
  try {
    const response: ExtendedBio | null = await HttpRequest.get(
      "/bio-w-reviews"
    );
    return response;
  } catch (err) {
    console.error(err);
    return null;
  }
};

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { Review } from "../../types";
import ReviewCard from "../Main/ReviewCard";
import ReviewListItem from "./ReviewListItem";
import ReviewDetail from "./ReviewDetail";

export interface PressReviewsProps {
  reviews: Review[];
}

const PressReviews: React.FC<PressReviewsProps> = ({ reviews }) => {
  const [currentReview, setCurrentReview] = useState<Review | null>(null);
  const { hash } = useLocation();

  useEffect(() => {
    if (!hash) {
      setCurrentReview(null);
      return;
    }
    const foundReview = reviews.find(
      (review) => review.id.split("#")[1] === hash.split("#")[1]
    );
    if (!foundReview) {
      return;
    }
    setCurrentReview(foundReview);
  }, [hash, reviews]);

  return (
    <>
      {currentReview ? (
        <ReviewDetail review={currentReview} />
      ) : (
        <>
          <Box
            sx={{
              display: { xs: "flex" },
              flexDirection: {
                xs: "column",
                md: "row",
              },
              justifyContent: "space-between",
              padding: "0 1rem",
              mt: "2rem",
            }}
          >
            {reviews.slice(0, 2).map((review: Review, index) => {
              return <ReviewCard key={review.id} review={review} />;
            })}
          </Box>
          <Box sx={{ my: { xs: "1rem", md: "3rem" }, padding: "0 1rem" }}>
            {reviews.slice(2).map((review: Review) => {
              return <ReviewListItem key={review.id} review={review} />;
            })}
          </Box>
        </>
      )}
    </>
  );
};

export default PressReviews;

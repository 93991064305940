import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  CardContent,
  ListItem,
  Typography,
} from "@mui/material";
import { Concert } from "../../types";
import { dateFormatter } from "../../utils/date";

const StyledCard = styled(Card)(({ theme }) => ({
  "&": {
    minHeight: "unset",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "noWrap",
    alignItems: "left",
    borderRadius: "0",
    padding: "0.5rem 0",
    borderBottom: "0.5px solid rgb(0 0 0 / 20%)",
    boxShadow: "none",
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    "&:last-of-type": {
      borderBottom: "unset",
    },
  },
}));

const ConcertCard = ({ concert }: { concert: Concert }) => {
  const { isActive, startDate, endDate, title, location, programs, musicians } = concert;
  if (isActive !== 'y') return <></>;

  const concertDateString = `${dateFormatter(startDate, true, true, false )} ${
    endDate
      ? " - " + dateFormatter(endDate, true, true, false)
      : ""
  }`;

  return (
    <StyledCard>
      <CardContent sx={{ padding: { xs: "0.5rem", md: "1rem" }, flexGrow: 1 }}>
        <Typography variant="mainH4" component="h3" fontWeight="bold">
          {title}
        </Typography>
        <Typography variant="mainH4" component="h3" color="primary.dark">
          {concertDateString}
        </Typography>
        <Typography variant="body1" mb={2} fontWeight="700">
          {location}
        </Typography>
        {programs?.map((program, index) => {
          return (
            <div key={index}>
              <ListItem sx={{ padding: 0, display: "list-item" }}>
                <Typography
                  component="span"
                  gutterBottom
                >{`${program.title} ${program.composer ? 'by ' + program.composer : ''} ${program.tag ? `(${program.tag})`: ''}`}</Typography>
              </ListItem>
              {program.movements?.map((movement, idx) => {
                return (
                  <ListItem key={idx} sx={{ padding: 0, ml: 3 }}>
                    <Typography
                      component="span"
                    >{movement}</Typography>
                  </ListItem>
                )
              })}
            </div>
          );
        })}
        {musicians && musicians.length > 0 && (
          <Box mt={2}>
            <Typography sx={{fontWeight: "700"}}>Musicians: </Typography>
            {musicians.map(({musician, instrument}) => <Typography key={musician}>{`${musician} ${instrument ? `(${instrument.toLowerCase()})` : ''}`}</Typography>)}
          </Box>
        )}
        
      </CardContent>
    </StyledCard>
  );
};

export default React.memo(ConcertCard);

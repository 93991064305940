import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Divider, Typography, Box, Button, CardMedia } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Writing } from "../../types";
import { dateFormatter } from "../../utils/date";

export interface WritingListItemProps {
  writing: Writing;
}

const WritingListItem: React.FC<WritingListItemProps> = ({ writing }) => {
  const navigate = useNavigate();
  const { category } = useParams();
  const { author, title, subtitle, contents, link, writtenFor, date, imgUrl } = writing;
  const handleClickWritingItem = () => {
    const writingId = writing.id.split("#")[1]
    navigate(`/writings/${category}#${writingId}`);
  };

  return (
    <Box
      sx={{
        mb: "0.5rem",
      }}
    >
      <Divider />
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: {
            md: "row",
          },
          justifyContent: "space-between",
          mt: "1rem",
        }}
      >
        <Box sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: {
            md: "column",
          },
          justifyContent: "space-between",
          width: "85%"
        }}>
          <Typography variant="pageH3">{title}</Typography>
          {subtitle && <Typography variant="subtitle2" sx={{textTransform: "capitalize"}}>{subtitle}</Typography>}
        </Box>
        <Box sx={{width: "15%", textAlign:"right"}}>
          <Typography variant="pageH3" color="primary.main">{date && dateFormatter(date, true, true, false)}</Typography>
        </Box>
      </Box>
      {contents && (
        <>
          <Box
            sx={{
              display: "-webkit-box",
              whiteSpace: "normal",
              wordBreak: "break-word",
              wordWrap: "break-word",
              overflow: "hidden",
              textOverflow: "ellipsis",
              "-webkit-line-clamp": "3",
              "-webkit-box-orient": "vertical",
              mt: "0.5rem",
            }}
          >
            {contents}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="text"
              sx={{ fontSize: "0.75rem" }}
              onClick={handleClickWritingItem}
            >
              Read More <ArrowForwardIcon sx={{ fontSize: "1rem" }} />
            </Button>
          </Box>
        </>
      )}
      {imgUrl && (
        <Box sx={{ padding: "2rem 0", display: "flex", justifyContent: "center" }}>
          <CardMedia
            src={imgUrl}
            alt={`${title} image`}
            component="img"
            sx={{
              height: "600px",
              objectFit: "contain",
              borderRadius: "1rem",
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default WritingListItem;
import React, { useState } from "react";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Link,
  Toolbar,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuBar from "../components/MenuBar";
import MenuDrawer from "../components/MenuDrawer";
import { TITLE, MENUS, HEADER_HEIGHT } from "../constants/index";
import { Menu } from "../types/index";

const ChangeBackgroundOnScroll = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window || undefined,
  });

  return React.cloneElement(children, {
    style: {
      backgroundColor: trigger ? "#fff" : "transparent",
    },
  });
};

const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const handleToggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      )
        return;
      setIsDrawerOpen(open);
    };

  return (
    <ChangeBackgroundOnScroll>
      <AppBar
        position="sticky"
        color="inherit"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          boxShadow: "none",
          height: HEADER_HEIGHT,
          width: "100vw",
          backgroundColor: "transparent",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: { xs: "row", md: "column" },
            maxWidth: "xl",
          }}
        >
          {/* LARGE SCREEN */}
          <Link
            underline="none"
            color="inherit"
            href="/"
            sx={{
              width: "100%",
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              component="h1"
              variant="mainH1"
              color="inherit"
              align="center"
              noWrap
              mt={4}
              sx={{ textTransform: "none" }}
            >
              {TITLE}
            </Typography>
          </Link>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Toolbar
              component="nav"
              variant="dense"
              sx={{
                display: { xs: "none", md: "grid" },
                gap: 1,
                gridTemplateColumns: `repeat(2, 82px) 118px 94px repeat(2, 80px) repeat(2, 68px) 72px 98px 188px`,
                overflow: "visible",
                padding: "0.5rem",
                align: "center",
              }}
            >
              {MENUS.map((menu: Menu) => {
                return (
                  <MenuBar
                    key={menu.title}
                    title={menu.title}
                    link={menu.link}
                    subMenu={menu.subMenu}
                  />
                );
              })}
            </Toolbar>
          </Box>

          {/* SMALL SCREEN */}
          <Link
            underline="none"
            color="inherit"
            href="/"
            sx={{
              width: "100%",
              justifyContent: "left",
              display: { xs: "flex", md: "none" },
              alignItems: "center",
              // paddingLeft: 2,
            }}
          >
            <Typography component="h4" variant="mainH4" color="inherit" noWrap>
              {TITLE}
            </Typography>
          </Link>
          <IconButton
            size="large"
            aria-label="website menus"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleToggleDrawer(true)}
            color="inherit"
            sx={{ display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <MenuDrawer
            menus={MENUS}
            isDrawerOpen={isDrawerOpen}
            handleToggleDrawer={handleToggleDrawer}
          />
        </Container>
      </AppBar>
    </ChangeBackgroundOnScroll>
  );
};

export default React.memo(Header);

import { useTheme } from "@mui/material/styles";
import { Container, Box, ImageList, ImageListItem } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import DownloadIcon from "@mui/icons-material/Download";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import React from "react";

export interface PhotosPanelProps {
  photos: string[];
  baseUrl: string;
}

const PhotosPanel: React.FC<PhotosPanelProps> = ({photos, baseUrl}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDownload = (imgUrl: string, id: string) => {
    fetch(imgUrl, {
      method: "GET",
      headers: { "Cache-Control": "no-cache" },
    })
      .then((response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = `hn-photo-${id}`;
          alink.click();
        });
      })
      .catch((error) => {
        console.log("failed to download: ", error);
      });
  };

  const handleOpenInNew = (imgUrl: string) => {
    window.open(imgUrl, "_blank");
  };

  return (
    <ImageList
      variant="masonry"
      sx={{
        width: "100%",
        height: "100%",
        pt: 3,
        paddingLeft: isMobile ? "16px" : "",
        paddingRight: isMobile ? "16px" : "",
      }}
      cols={isMobile ? 1 : 3}
      gap={8}
    >
      {photos?.map((photo, index) => {
        const imgUrl = `${baseUrl}${photo}`;
        return (
          <ImageListItem
            key={index}
            sx={{ "&:hover .MuiBox-root": { display: "flex" } }}
          >
            <img src={imgUrl} loading="lazy" style={{ borderRadius: "1rem" }} />
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                display: "none",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor: "rgba(255, 255, 255, 30%)",
                "& .MuiSvgIcon-root": {
                  margin: "0.5rem",
                  backgroundColor: (theme) => theme.palette.neutral.main,
                  borderRadius: "0.5rem",
                  color: "#FFF",
                },
                "& .MuiSvgIcon-root:hover": {
                  transform: "scale(1.1)",
                },
              }}
            >
              <DownloadIcon
                fontSize="large"
                onClick={(e) => handleDownload(imgUrl, index.toString())}
              />
              <OpenInNewIcon
                fontSize="large"
                onClick={(e) => handleOpenInNew(imgUrl)}
              />
            </Box>
          </ImageListItem>
        );
      })}
    </ImageList>
  );
};

export default PhotosPanel;

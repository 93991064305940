import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Album } from "../types/index";

interface AlbumModalProps {
  open: boolean;
  handleClose: (event: React.MouseEvent) => void;
  album: Album;
}

const StyledBox = styled(Box)(({ theme }) => ({
  "&": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minHeight: "50%",
    maxHeight: "100vh",
    maxWidth: "600px",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    paddingBottom: "2rem",
    borderBottom: `8px solid ${theme.palette.primary.main}`,
    overflowY: "auto",
  },
}));

const AlbumModal = ({ open, handleClose, album }: AlbumModalProps) => {
  const { id, imageUrl, title, link, list, publisher, subtitle, recordNumber } = album;
  return (
    <Modal open={open} onClose={handleClose}>
      <StyledBox sx={{ width: { xs: "100vw", md: "50vw" } }}>
        <CardActions sx={{ alignSelf: "flex-end" }}>
          <IconButton color="info" aria-label="close modal" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </CardActions>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContents: "space-between",
          }}
        >
          <Typography
            variant="mainH4"
            component="h3"
            sx={{ textAlign: "center"}}
          >
            {title}
          </Typography>
          {subtitle && <Typography
            variant="subtitle1"
            component="h3"
            sx={{ textAlign: "center", mb: 2, maxWidth: "360px" }}
          >
            {subtitle}
          </Typography>}
          {imageUrl && (
            <CardMedia
              component="img"
              src={imageUrl}
              alt={title}
              sx={{
                width: {xs: "100%", md: "calc(100% - 4rem)"},
                maxWidth: "360px",
                borderRadius: "10px",
                mt: 2,
                mb: 2,
              }}
            ></CardMedia>
          )}
          {!imageUrl && (
            <Box
              sx={{
                width: 240,
                height: 200,
                backgroundColor: "info",
              }}
            />
          )}
          <Box sx={{ mb: 2, maxWidth: "360px" }}>
            {list &&
              list.map((program, index) => {
                return (
                  <Box key={index}>
                    <Typography variant="body1" component="span" sx={{fontWeight: 700}}>
                      {program.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      component="span"
                    >{program.composer ? ` by ${program.composer}` : ""}</Typography>
                  </Box>
                );
              })}
            {publisher && <Box mt={2}>
              <Typography
                variant="body1"
                component="span"
              >{publisher}</Typography>
            </Box>}
            {recordNumber && <Box mt={2}>
              <Typography
                variant="body1"
                component="span"
              >{recordNumber}</Typography>
            </Box>}
            
          </Box>
        </CardContent>
      </StyledBox>
    </Modal>
  );
};

export default AlbumModal;

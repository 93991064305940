import { Container, Typography, Box, Link } from '@mui/material';
import React, { useEffect, useState } from "react";
import PageBannerImg from '../../components/PageBannerImg';
import PageTitle from '../../components/PageTitle';
import { Publish } from '../../types';
import { getPublisher } from '../../http-requests';
import { PUBLISHER } from '../../constants';
import PublishItem from './PublishItem';
import Loading from '../../components/Loading';

interface Publishes {
  [key: string]: Publish[];
}

const sortPublishesByCategory = (publishes: Publish[]) => {
  return publishes.reduce((acc, publish, index, publishes) => {
    if(!publish.category) return acc;
    if(!acc[publish.category]) {
      acc[publish.category] = [publish];
    } else {
      acc[publish.category].push(publish);
    }
    return acc;
  }, {} as Publishes)
}

const Publisher = () => {
  const [publishes, setPublishes] = useState<Publishes>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { name, url, email } = PUBLISHER;
  
  useEffect(() => {
    const getPublisherHttpRequest = async () => {
      const res = await getPublisher();
      if (res?.length) setPublishes(sortPublishesByCategory(res));
    };
    getPublisherHttpRequest();
    setIsLoading(false);
  }, [getPublisher, setPublishes, setIsLoading])
  
  if(isLoading) {
    return <Loading />
  }

  return (
    <Container sx={{ padding: { xs: '0', md: '1rem' } }}>
      <Box>
        <PageTitle title="Publisher" />
        <PageBannerImg imgUrl="https://s3.amazonaws.com/images.hyoshin.na/pages/hsna_publisher_cover_img.jpeg" imgTitle="publisher image"/>
        <Box sx={{display: "flex", flexDirection: "column", padding: "1rem"}}>
          <Typography variant="pageH2">{name}</Typography>
          <Typography variant="body1"><Link href={url} target="_blank" underline="none">{url}</Link></Typography>
          <Typography variant="body1"><Link href={`mailto:${email}`} underline="none">{email}</Link></Typography>
        </Box>
        <Box sx={{padding: "1rem"}}>
          {Object.keys(publishes).map((category) => {
            return (
              <Box key={category} sx={{marginTop: 3}}>
                <Typography variant="pageH3">{category}</Typography>
                {publishes[category].map((publish: Publish, index) => {
                  return <PublishItem key={index} publish={publish} />
                })}
              </Box>
            )
            })}
        </Box>
      </Box>
    </Container>
  )
}

export default Publisher;




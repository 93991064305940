import React, { useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Review } from "../../types";
import { dateFormatter } from "../../utils/date";

export interface ReivewDetailProps {
  review: Review;
}

const ReviewDetail: React.FC<ReivewDetailProps> = ({ review }) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Stack
      direction={{ xs: "column", md: "column-reverse" }}
      sx={{
        margin: { xs: "0", md: "2rem 0" },
        padding: { xs: "1rem", md: "0" },
        border: "none",
        borderRadius: "unset",
        boxShadow: "unset",
      }}
    >
      <Box sx={{ padding: { sm: "0 1.5rem", md: 0 }, mb: "2rem" }}>
        <Typography variant="pageH3" component="h2" color="primary.main">
          {review.title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mb: "1rem",
          }}
        >
          <Typography sx={{ fontWeight: 700 }}>
            {review.writer && `by ${review.writer.replace(",", "").trim()}`}
            {review.date &&
              `, on ${dateFormatter(review.date, true, true, false)}`}
          </Typography>
        </Box>
        <Typography variant="body1">{review.content}</Typography>
      </Box>
    </Stack>
  );
};

export default ReviewDetail;

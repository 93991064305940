import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";
// import { getReviews } from "../../http-requests";
import { REVIEWS, MENUS } from "../../constants";
import { Review, ReviewType } from "../../types";
import PageTitle, { PageTitlePropsType } from "../../components/PageTitle";
import PageBannerImg from "../../components/PageBannerImg";
import PressNotices from "./PressNotices";
import PressReviews from "./PressReviews";
import { getReviews } from "../../http-requests";
import Loading from "../../components/Loading";

const Reviews = () => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { category } = useParams();
  const siblingPages = MENUS.find(
    (menu) => menu.title.toLowerCase() === "reviews"
  )?.subMenu as PageTitlePropsType["siblingPages"];

  useEffect(() => {
    const getBioHttpRequest = async () => {
      const res: Review[] = await getReviews();
      const reviewList = res?.length ? res : REVIEWS;
      setReviews(reviewList.filter((review) => review.type === category));
    };

    getBioHttpRequest();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Loading />
    );
  }

  return (
    <Container sx={{ padding: { xs: "0", md: "1rem" } }}>
      <PageTitle
        title={
          category === ReviewType.PRESS_REVIEW
            ? "Press Reviews"
            : "Press Notices"
        }
        siblingPages={siblingPages}
      />
      <PageBannerImg
        imgUrl="https://s3.amazonaws.com/images.hyoshin.na/photos/hsnagaya01.jpg"
        imgTitle="hyo-shin seminar"
      />
      {category === ReviewType.PRESS_REVIEW ? (
        <PressReviews reviews={reviews} />
      ) : (
        <PressNotices reviews={reviews} />
      )}
    </Container>
  );
};

export default Reviews;

export const WOODEN_FISH = {
  main: 'The Wooden Fish Ensemble presents concerts of music and musicians from a variety of cultural and national backgrounds working together in a collaborative way. \n\n The Ensemble has presented concerts of traditional music from Asia and new music by a diverse group of composers that includes John Cage, Christian Wolff, Frederic Rzewski, Morton Feldman, Hyo-shin Na and Walter Zimmermann. They have given numerous world premieres.',
  subtitle: 'Selected List of Concerts, Lectures and Residencies – Hyo-shin Na/Wooden Fish Ensemble',
  imgUrl: ['wf001.jpg', 'wf002.jpg', 'wf003.jpg'
  ],
  items: [
    {
      year: '2003',
      title: '65th Birthday Concert of the Japanese composer Yuji Takahashi',
      location: 'San Francisco',
      isActive: 'y',
    },
    {
      year: '2021',
      title: 'Live Stream Concert - World Premieres for violin duo by Belgian iconoclast Boudewijn Buckinx and San Francisco’s own Hyo-shin Na, plus works by Satie, Pärt, Rzewski, and Bach/Busoni',
      isActive: 'y',
    },
    {
      year: '2020',
      title: 'Two Concerts - Wooden Fish Ensemble performs the music of Frederic Rzewski and Hyo-shin Na, as well as Korean folk songs',
      isActive: 'y',
    },
  ]
}
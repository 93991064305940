import { Container, Typography, Box, Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageBannerImg from "../../components/PageBannerImg";
import PageTitle from "../../components/PageTitle";
import PhotosPanel from "../../components/PhotosPanel";
import { getWoodenFish } from "../../http-requests/WoodenFishHttpRequest";
import { WoodenFish as WoodenFishType } from "../../types";
import { WOODEN_FISH } from "../../constants/woodenfish";
import { REACT_APP_WF_IMAGE_BASE_URL } from "../../constants/env";
import Loading from "../../components/Loading";

const WoodenFish = () => {
  const [woodenfish, setWoodenFish] = useState<WoodenFishType>(WOODEN_FISH);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getWoodenFishHttpRequest = async () => {
      const res = await getWoodenFish();
      if (res && Object.keys(res).length) setWoodenFish(res);
    };
    getWoodenFishHttpRequest();
    setIsLoading(false);
  }, [getWoodenFish]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container sx={{ padding: { xs: "0", md: "1rem" } }}>
      <Box>
        <PageTitle title="Wooden Fish Ensemble" />
        <PageBannerImg
          imgUrl="https://s3.amazonaws.com/images.hyoshin.na/pages/woodenfishCDcover.jpg"
          imgTitle="dissertation image"
        />
        <Box sx={{ padding: "1rem" }}>
          <Typography mb={3}>{woodenfish.main}</Typography>
          <Typography
            color="primary"
            variant="pageH2"
            sx={{ textTransform: "unset" }}
          >
            {woodenfish.subtitle}
          </Typography>
          <Box mt={2} sx={{ display: "flex", flexDirection: "column" }}>
            {woodenfish.items &&
              woodenfish.items.map((item, index) => {
                const { year, title, location, isActive } = item;
                if (isActive !== "y") return;
                return (
                  <Typography key={index} mb={1}>{`${year} ${title}${
                    location ? ", " + location : ""
                  }`}</Typography>
                );
              })}
          </Box>
        </Box>
        {woodenfish.imgUrl && (
          <PhotosPanel
            photos={woodenfish.imgUrl}
            baseUrl={REACT_APP_WF_IMAGE_BASE_URL}
          />
        )}
      </Box>
    </Container>
  );
};

export default WoodenFish;

import { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Album as AlbumCardProps } from "../types";
import { getComposersLastNames } from "../utils/program";
import AlbumModal from "./AlbumModal";

const AlbumCard = ({ album }: { album: AlbumCardProps }) => {
  const { id, imageUrl, title, subtitle, link, list } = album;
  const [modalOpen, setModalOpen] = useState(false);
  const composersLastNames = list && getComposersLastNames(list);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxShadow: "none",
        backgroundColor: "transparent",
        height: "100%",
      }}
    >
      {imageUrl && (
        <CardMedia
          component="img"
          src={imageUrl}
          alt={title}
          sx={{
            width: "300px",
            height: "260px",
            borderRadius: "10px",
            objectFit: "unset",
          }}
        ></CardMedia>
      )}
      {!imageUrl && (
        //TODO: THIS CAN BE A COMPONENT SUCH AS <IMAGEPLACEHOLDER />
        <Box
          sx={{
            width: 240,
            height: 200,
            backgroundColor: { xs: "secondary.main", lg: "primary.main" },
          }}
        />
      )}
      <CardContent sx={{ width: "300px", flexGrow: 1 }}>
        <Typography
          gutterBottom
          variant="mainH5"
          component="h3"
          textAlign="center"
        >
          {title}
        </Typography>
        {composersLastNames && (
          <Typography variant="body1" textAlign="center" color="text.secondary">
            {`Works by ${getComposersLastNames(list)}`}
          </Typography>
        )}
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="info"
          variant="contained"
          onClick={handleModalOpen}
        >
          View Details
        </Button>
        <AlbumModal
          open={modalOpen}
          handleClose={handleModalClose}
          album={album}
        />
      </CardActions>
    </Card>
  );
};

export default AlbumCard;

import React, { useEffect } from "react";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { Writing } from "../../types";
import { dateFormatter } from "../../utils/date";
import { useNavigate, useParams } from "react-router";

export interface WritingDetailProps {
  writing: Writing;
  previousWritingId?: string;
  nextWritingId?: string;
}

const WritingDetail: React.FC<WritingDetailProps> = ({ 
  writing,
  previousWritingId,
  nextWritingId
}) => {
  const { author, title, subtitle, contents, link, writtenFor, date } = writing;
  const navigate = useNavigate();
  const { category } = useParams();


  useEffect(() => {
    window.scrollTo({top: 0});
  }, [])

  return (
    <Stack
      direction="column"
      sx={{
        margin:{xs: "0", md: "2rem 0"},
        padding: { xs: "1rem", md: "0" },
        border: "none",
        borderRadius: "unset",
        boxShadow: "unset",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: { sm: "0 1.5rem", md: 0 }, mb: "2rem" }}>
        <Box sx={{display: "flex", flexDirection: "column", width: "85%"}}>
          <Typography variant="pageH3" component="h2" color="primary.main">
            {title}
          </Typography>
          {subtitle && <Typography variant="body1" sx={{textTransform: "capitalize", mb: 2}}>{subtitle}</Typography>}
        </Box>
        {date && (<Box sx={{width: "15%", textAlign: "right"}}>
          <Typography variant="pageH3" color="primary.main">{dateFormatter(date, true, true, false)}</Typography>
        </Box>)}
      </Box>
      <Box>
        <Typography variant="body1" sx={{whiteSpace: "pre-line"}}>{contents}</Typography>
      </Box>
      <br />
      <Divider />
      <Box display="flex" justifyContent="space-between" p="10px">
        <Button
          variant="outlined"
          color="info"
          size="small"
          disabled={previousWritingId ? false : true}
          onClick={() => {
            navigate(`/writings/${category}#${previousWritingId}`);
          }}
        >
          Pre
        </Button>
        <Button
          variant="outlined"
          color="info"
          size="small"
          disabled={nextWritingId ? false : true}
          onClick={() => {
            navigate(`${nextWritingId}`);
          }}
        >
          Next
        </Button>
      </Box>
    </Stack>
  );
};

export default WritingDetail;

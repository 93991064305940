import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Review } from "../../types/index";
import quoteIcon from "../../asset/quoteIcon.png";

const StyledQuoteIcon = styled("img")``;

const PressNoticeCard = ({ review }: { review: Review }) => {
  const { org, content } = review;

  return (
    <Card
      variant="outlined"
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: { md: "calc(100% - 3rem)" },
        backgroundColor: "transparent",
        overflow: "visible",
        mb: { xs: 6 },
      }}
    >
      <StyledQuoteIcon
        src={quoteIcon}
        alt="quote-icon"
        sx={{
          width: { xs: "2rem", md: "unset" },
          position: "absolute",
          top: { xs: "-1rem", md: "-1.5rem" },
          left: { xs: "-1rem", md: "-1.5rem" },
        }}
      />
      <CardContent sx={{ padding: "2rem", flexGrow: 1 }}>
        <Typography variant="mainH4" component="div">
          {content}
        </Typography>
        <Typography variant="h6" component="div" sx={{ textAlign: "end" }}>
          {`- ${org}`}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PressNoticeCard;

export interface Menu {
  title: string;
  link?: string;
  subMenu?: Menu[];
}

export interface Program {
  title?: string;
  composer: string;
  tag?: string;
  movements?: string[];
}

export interface Musician {
  musician: string;
  instrument?: string;
}

export interface Concert {
  isActive: string;
  location: string;
  startDate: string;
  endDate?: string;
  programs: Program[];
  musicians?: Musician[];
  createdAt: string;
  id: string;
  timeZone: string;
  title?: string;
  entityTimestamp: string;
}

export interface Album {
  id: string;
  title: string;
  subtitle?: string;
  link?: string;
  imageUrl?: string;
  list?: { composer: string; title: string }[];
  musicians?: string[];
  publisher?: string;
  year?: number;
  recordNumber?: string;
}

export enum ReviewType {
  PRESS_REVIEW = "review",
  PRESS_NOTICE = "notice",
}

export interface Review {
  id: string;
  type: ReviewType;
  date?: string;
  writer?: string;
  org?: string;
  source?: string;
  title?: string;
  content?: string;
  link?: string;
  summary?: string;
  isBio?: boolean;
}

export interface Youtube {
  id: string;
  date?: string;
  title?: string;
  content?: string;
  youtubeUrl?: string;
  imgUrl?: string;
  thumbnail?: string;
  isActive?: string;
  type?: string;
}

export interface Profile {
  concerts?: Concert[];
  youtubes?: Youtube[];
  reviews?: Review[];
  albums?: Album[];
  [key: string]: any;
}

export interface Bio {
  isActive?: string;
  description: string;
  id: string;
  language: "english" | "korean";
  title?: string;
  createdAt?: string;
  entityTimestamp?: string;
}

export interface ExtendedBio extends Bio {
  bioReviews?: Review[];
}

export interface Writing {
  id: string;
  category: string;
  author: string;
  title: string;
  subtitle?: string;
  contents?: string;
  link?: string;
  writtenFor?: string;
  date?: string;
  imgUrl?: string;
}

export interface WorkType {
  id: string;
  year: number;
  title: string;
  isFirstPerformance?: string;
  isActive?: string;
  date?: string;
  createdAt?: string;
  firstPerformance?: {
    place: string;
    date: string;
    musician?: string;
  };
  detail?: {
    publisher?: string;
    recording?: string;
  };
  entityTimestamp: string;
  movements?: string[];
  programNote?: string;
}
export interface WorksType {
  [key: number]: WorkType[];
}

export interface Dissertation {
  degree: string;
  id: string;
  instrument: string;
  isActive: string;
  language: string;
  publisher: string;
  title: string;
  writer: string;
  year: number;
}

export interface Dissertations {
  [key: number]: {
    [key: number]: Dissertation[];
  };
}
export interface Publish {
  category: string;
  coverImageUrl: string;
  createdAt: string;
  date: string;
  description: string;
  id: string;
  isActive: string;
  itemNumber: string;
  title: string;
}

export interface WoodenFish {
  main: string;
  subtitle: string;
  imgUrl: string[];
  items: {
    isActive: string;
    year: string;
    title: string;
    location?: string;
  }[];
}

import React, { useCallback, useEffect, useState } from 'react';
import { Youtube } from "../../types";
import { YOUTUBES, MENUS } from "../../constants";
import { Container, Box, Grid, Typography } from '@mui/material';
import YoutubeCard from "../../components/YoutubeCard";
import { getYoutubes } from '../../http-requests';
import PageTitle, { PageTitlePropsType } from '../../components/PageTitle';
import Loading from '../../components/Loading';

interface SortedYoutubes {
  [key: string]: Youtube[];
}

const Youtubes = () => {
  const [sortedYoutubes, setSortedYoutubes] = useState<SortedYoutubes>({})
  const [isLoading, setIsLoading] = useState(true);
  const siblingPages = MENUS.find(menu => menu.title.toLowerCase() === "recordings")?.subMenu as PageTitlePropsType["siblingPages"];

  const sortYoutubesByType = useCallback((youtubes: Youtube[]) => {
    let result: SortedYoutubes = {};
    youtubes.forEach(youtube => {
      let { type } = youtube;
      if(!type) return;
      if(result[type]) {
        result[type] = result[type].concat(youtube);
      } else {
        result[type] = [youtube];
      }
    });
    return result;
  }, []);

  useEffect(() => {
    const getYoutubeHttpRequest = async () => {
      const res = await getYoutubes();
      (res && !!res.length) ? setSortedYoutubes(sortYoutubesByType(res)) : setSortedYoutubes(sortYoutubesByType(YOUTUBES));
    }
    getYoutubeHttpRequest();
    setIsLoading(false);
  }, [setIsLoading, getYoutubes, setSortedYoutubes, sortYoutubesByType])

  if (isLoading) {
    return (
      <Loading />
    );
  }
  
  return (
    <Container sx={{ padding: { xs: '0', md: '1rem' } }}>
      <Box>
        <PageTitle title="Youtube" siblingPages={siblingPages}/>
        {Object.keys(sortedYoutubes).map(key => {
          return (
            <Box key={key} mt={3}>
              <Typography variant="pageH2" color="primary" sx={{marginLeft: {xs: 2, md: 0}}}>{key}</Typography>
              <Grid container spacing={{xs: 2, md: 4}} sx={{padding:{ xs: "2rem 1rem", md: "2rem 0"}}}>
                {sortedYoutubes[key]?.map((youtube) => {
                  return (
                      <Grid item key={youtube.id} xs={12} sm={6} lg={4} sx={{padding: "2rem 0"}}>
                        <YoutubeCard youtube={youtube} />
                      </Grid>
                  )
                })}
              </Grid>
            </Box>
          )
        })}
      </Box>
    </Container>
  );
};

export default Youtubes;

import { Menu } from "../types";
import {
  HEADER_HEIGHT,
  FOOTER_HEIGHT,
  ALBUM_CAROUSEL_BREAKPOINTS,
  REVIEW_CAROUSEL_BREAKPOINTS,
  SCORE_CAROUSEL_BREAKPOINTS,
} from "./styles";
import { ALBUMS, YOUTUBES } from "./recordings";
import { REVIEWS } from "./reviews";
import { CONCERTS } from "./concerts";
import { BIOGRAPHY } from "./biography";
import { WRITINGS } from "./writings";
import { PHOTOS } from "./photos";
import { WORKS } from "./works";
export * from "./publisher";
export * from "./woodenfish";

export const NodeEnvironment = {
  DEV: "development",
};

export const TITLE = "HYO-SHIN NA, Composer";
export const MENUS: Menu[] = [
  {
    title: "Biography",
    link: "/biography",
  },
  {
    title: "Concerts",
    subMenu: [
      {
        title: "Concert Schedule",
        link: "/concerts/schedule",
      },
      {
        title: "Concert History",
        link: "/concerts/history",
      },
    ],
  },
  {
    title: "List of Works",
    link: "/works",
  },
  {
    title: "Recordings",
    subMenu: [
      {
        title: "CDs",
        link: "/recordings/cds",
      },
      {
        title: "Youtube",
        link: "/recordings/youtube",
      },
    ],
  },
  {
    title: "Reviews",
    subMenu: [
      {
        title: "Press Reviews",
        link: "/reviews/review",
      },
      {
        title: "Press Notices",
        link: "/reviews/notice",
      },
    ],
  },
  {
    title: "Writings",
    subMenu: [
      {
        title: "Author",
        link: "/writings/author",
      },
      {
        title: "Korea Times SF",
        link: "/writings/koreatimessf",
      },
      {
        title: "Writings In Korean",
        link: "/writings/writingsinkorean",
      },
    ]
  },
  {
    title: "Scores",
    link: "/scores",
  },
  {
    title: "Publisher",
    link: "/publisher",
  },
  {
    title: "Photos",
    link: "/photos",
  },
  {
    title: "Dissertations",
    link: "/dissertations",
  },
  {
    title: "Wooden Fish Ensemble",
    link: "/woodenfishensemble",
  },
];
export const MONTHS = [
  "",
  "January",
  "February",
  "March",
  "Aprin",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export {
  HEADER_HEIGHT,
  FOOTER_HEIGHT,
  ALBUM_CAROUSEL_BREAKPOINTS,
  REVIEW_CAROUSEL_BREAKPOINTS,
  SCORE_CAROUSEL_BREAKPOINTS,
  ALBUMS,
  YOUTUBES,
  REVIEWS,
  CONCERTS,
  BIOGRAPHY,
  WRITINGS,
  PHOTOS,
  WORKS,
};

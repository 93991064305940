import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  ListItem,
  Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { WorkType } from "../../types";
import { dateFormatter } from "../../utils/date";
import { YoutubeSearchedForOutlined } from "@mui/icons-material";
import ProgramNote from "./ProgramNote";

const StyledCard = styled(Card)(({ theme }) => ({
  "&": {
    minHeight: "unset",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "noWrap",
    alignItems: "left",
    borderRadius: "0",
    padding: "0.5rem 0",
    borderBottom: "0.5px solid rgb(0 0 0 / 20%)",
    boxShadow: "none",
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    "&:last-of-type": {
      borderBottom: "unset",
    },
  },
}));

const WorkCard = ({ work }: { work: WorkType }) => {
  const { year, title, isFirstPerformance, firstPerformance, detail, movements, programNote } = work;

  return (
    <StyledCard>
      <CardContent sx={{ padding: { xs: "0.5rem 0", md: "1rem" }, flexGrow: 1, '&:last-of-type': { paddingBottom: { xs: "0.5rem 0", md: "1rem" }} }}>
        <Typography variant="pageH3" component="h3" fontWeight="bold">
          { title }
        </Typography>
        {movements && movements?.map((movement, index) => {
          return (
            <ListItem key={index} sx={{ padding: 0, marginX: 1 }}>
              <Typography
                component="span"
                gutterBottom
              >{movement}</Typography>
            </ListItem>
          );
        })}
        {firstPerformance && (firstPerformance.date || firstPerformance.musician || firstPerformance.place) && 
          <Box sx={{
            display: "flex", 
            flexDirection: {xs: "column", md: "row"}, 
            alignItems: "center"
          }}>
            <Typography variant="body1" mr={1}>
              {`First Performance: ${firstPerformance.date ?? ''} ${firstPerformance.place ? 'at ' + firstPerformance.place : ''} ${firstPerformance.musician ? 'by ' + firstPerformance.musician : ''}`}
            </Typography>
          </Box>
        }
        {detail && (
          <>
            {detail.publisher && (
              <Typography variant="body2">{detail.publisher}</Typography>
            )}
          </>
        )}
        {programNote && <ProgramNote programNote={programNote} />}
      </CardContent>
    </StyledCard>
  );
};

export default React.memo(WorkCard);

import { MONTHS } from "../constants";
import { Concert } from "../types";

export const dateFormatter = (
  dateInput: string,
  abbreviate = false,
  returnStringTypeDate = true,
  returnTime = true,
): string => {
  if(!(/^\d/g).test(dateInput)) return dateInput;
  const newDate = new Date(dateInput);
  if(isNaN(newDate.getTime())) return dateInput;
  const newDateISOString = new Date(dateInput).toISOString().split('T')[0].split('-');
  const month = !returnStringTypeDate
    ? newDateISOString[1]
      : abbreviate
      ? `${MONTHS[+newDateISOString[1]].slice(0,3)}.`
      : MONTHS[+newDateISOString[1]] ;
  const date = newDateISOString[2];
  const year = newDateISOString[0];
  const hour = newDate.getHours() % 12;
  const minute = newDate.getMinutes();
  const meridiem = newDate.getHours() / 12 === 0 ? "AM" : "PM";

  return returnTime
    ? `${month} ${date}, ${year} ${hour}:${
        minute < 10 ? "0" + minute : minute
      } ${meridiem}`
    : `${month} ${date}, ${year}`;
};

export const extractConcertYears = (concerts: Concert[]) => {
  const years = new Set();

  for (let concert of concerts) {
    const newDate = new Date(concert.startDate);
    let startDateYear;
    if(isNaN(newDate.getTime())) {
      const extractedYear = concert.startDate.match(/\d{4}$/);
      if (extractedYear) startDateYear = extractedYear[0];
    } else {
      startDateYear = newDate.toISOString().split('-')[0];
    }
    if(!years.has(startDateYear)) years.add(startDateYear);
  }

  return Array.from(years) as string[];
}
import { WorkerOptions } from "worker_threads";
import { WorksType } from "../types";

export const WORKS: WorksType = {
  2022: [
  {
      "isFirstPerformance": "y",
      "isActive": "y",
      "date": "2022-01-01T00:00:00.000Z",
      "detail": {
          "recording": "",
          "publisher": ""
      },
      "firstPerformance": {
          "date": "45038",
          "musician": "",
          "place": "Campbell Recital Hall"
      },
      "year": 2022,
      "createdAt": "2022-01-01T00:00:00.000Z",
      "id": "WORK#96c28f2e-2c29-448c-b818-3d42d611c66a",
      "title": "On a Cargo Ship for clarinet in B flat, violin, viola, cello, sanjo kayageum, and piano",
      "entityTimestamp": "WORK#2022-01-01T00:00:00.000Z"
  },
  {
      "isFirstPerformance": "y",
      "isActive": "y",
      "date": "2022-01-01T00:00:00.000Z",
      "detail": {
          "recording": "",
          "publisher": ""
      },
      "firstPerformance": {
          "date": "45037",
          "musician": "",
          "place": "Campbell Recital Hall"
      },
      "year": 2022,
      "createdAt": "2022-01-01T00:00:00.000Z",
      "id": "WORK#9c3ecd29-c76c-475e-8ee3-b268b6439627",
      "title": "Kayageum, Piano for sanjo kayageum and piano",
      "entityTimestamp": "WORK#2022-01-01T00:00:00.000Z"
  },
  {
      "isFirstPerformance": "y",
      "isActive": "y",
      "date": "2022-01-01T00:00:00.000Z",
      "detail": {
          "recording": "",
          "publisher": ""
      },
      "firstPerformance": {
          "date": "45038",
          "musician": "",
          "place": "Campbell Recital Hall"
      },
      "year": 2022,
      "createdAt": "2022-01-01T00:00:00.000Z",
      "id": "WORK#d4723667-a60c-4bbd-b5d0-4720a69980d3",
      "title": "While Crossing for clarinet in B flat, violin, viola, cello, 25 string kayageum, and piano",
      "entityTimestamp": "WORK#2022-01-01T00:00:00.000Z"
  },
  {
      "isFirstPerformance": "y",
      "isActive": "y",
      "date": "2022-01-01T00:00:00.000Z",
      "detail": {
          "recording": "",
          "publisher": ""
      },
      "firstPerformance": {
          "date": "Spring of 2023",
          "musician": "Kevin Lee Sun",
          "place": ""
      },
      "year": 2022,
      "createdAt": "2022-01-01T00:00:00.000Z",
      "id": "WORK#a4da364a-bd89-4ea7-a444-67d4ab909c25",
      "title": "Song So Old (for piano solo)",
      "entityTimestamp": "WORK#2022-01-01T00:00:00.000Z"
  }
]
}